import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"

const Contact = () => (
  <Layout>
    <SEO title="Contact us" />

    <div className="container contact grid">
      <div className="contact__content">
        <h1 className="contact__title">Engage with us at Upright Labs</h1>
        <p>
          Our team is a distributed workforce across the United States. Bringing
          a whole range of different expertise and backgrounds.
        </p>
        <p>
          In 2019, we supported operations in 20+ states, participated in 1,000
          meetings, and wrote over 100,000 lines of code.
        </p>
        <p>
          We look forward to hearing how we can help you and your team grow and
          streamline your operations.
        </p>

        <div className="contact__details">
          <a
            href="mailto:team@uprightlabs.com"
            className="contact__details-mail"
          >
            team@uprightlabs.com
          </a>
        </div>
      </div>

      <ContactForm className="form-contact contact__form" includeMessage />
    </div>
  </Layout>
)

export default Contact
